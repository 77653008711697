import React from 'react'

import { Link } from 'gatsby'

import {getPageUrl} from '../lib/helpers'

import AdSense from "./adsense";
import ArticleComments from "./article-comments";
import ArticleFooter from "./article-footer"
import AboutSideCallout from './about-sidecallout.js'

import * as styles from './article-wrapper.module.css'

const ArticleWrapper = ({article, children, ezoic_ad_ids, show_about_sidecallout = true}) => {

  if (!ezoic_ad_ids || ezoic_ad_ids.length == 0){
    ezoic_ad_ids = ["101","104"]
  }
  var adsense_ad_ids = [
    "1676918912", // top-left menu
    "9508658542"]
  
  return (
    <>
      <div className={styles.root}>
          <div>

            <div itemScope itemType="https://schema.org/WebPageElement" id="mainContentOfPage" className={styles.main}>{children}</div>
            
            <div className={styles.main}>
                <ArticleFooter/>
                { article.show_comments && 
                    <ArticleComments article={article}/>
                }
            </div>
          </div>
          
          <aside className={styles.rightPanel}>
            <div className={styles.rightPanelPadded}>
              {show_about_sidecallout && (
                <div>
                  <AboutSideCallout></AboutSideCallout>
                </div>
              )}
              <div className={styles.stickableArea}>
                <div className={styles.stickyMenu}>
                  {process.env.ADSENSE_CLIENT_ID && 
                    <AdSense id={adsense_ad_ids[0]}/>
                  }
                  
                  {process.env.EZOIC_ACCOUNT_ID && 
                    <>
                    <span dangerouslySetInnerHTML={{ __html: `<div id="ezoic-pub-ad-placeholder-`+ezoic_ad_ids[0]+`"> </div>`}}/>
                    <span dangerouslySetInnerHTML={{ __html: `<div id="ezoic-pub-ad-placeholder-`+ezoic_ad_ids[1]+`"> </div>`}}/>
                    </>
                  }
                  { article.related_pages && article.related_pages.length > 0 &&
                  <div className={styles.subMenu}>
                    <span className={styles.rightSubmenu}>Related Pages</span>
                    <ul className={styles.similarPages}>
                    {article.related_pages.map(post => post && (
                        <li key={`similar_${post.id}_${post.slug}`} className={styles.listItem}>
                          <Link to={getPageUrl(post)}>{post.title_short ? post.title_short : post.title}</Link>
                        </li>
                    ))}
                    </ul>
                  </div>
                  }
                  { article.categorytopic_pages && article.categorytopic_pages.length > 0 &&
                  <div className={styles.subMenu}>
                    <span className={styles.rightSubmenu}>Related Topics and Categories</span>
                    <ul className={styles.similarPages}>
                    {article.categorytopic_pages.map(topic => topic && (
                        <li key={`topic_${topic.id}`} className={styles.listItem}>
                          <Link to={getPageUrl(topic)}>{topic?.parent_page && <>{topic.parent_page?.title_short}: </>} {topic.primary_topic ? topic.primary_topic : <>{topic.title_short ? topic.title_short : topic.title}</>}</Link>
                        </li>
                    ))}
                    </ul>
                  </div>
                  }
                </div>
              </div>
            </div>
          </aside>

      </div>
    </>
  )
}

export default ArticleWrapper
