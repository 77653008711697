import React from 'react';
import GoogleMapReact from 'google-map-react';

function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export default function ArticleMap(props) {
    const article = props.article
    const AnyReactComponentMain = ({ text, entity, link }) => <>{entity?.latitude && entity?.longitude && 
        <div style={{
        color: 'white', 
        background: 'gray',
        padding: '10px 12px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        transform: 'translate(-50%, -50%)'
      }}>
          {link? <a style={{color:'white', textDecoration:'none'}} href={"#"+link}>{text}</a> : text}
        </div>}</>;
    const AnyReactComponent = ({ text, entity, component }) => <>{entity?.latitude && entity?.longitude && 
        
          <>{component?.title? <a style={{fontSize:'2.5em', textDecoration:'none', fontWeight:'bolder'}} href={"#"+string_to_slug(component.title)}><div style={{
        color: 'white', 
        background: 'red',
        padding: '10px 12px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        border: '3px solid white',
        transform: 'translate(-50%, -50%)',
        width: '25px',
        height: '25px'
      }}>{text}</div></a> : text}</>
        }</>;

    var defaultProps = {};
    // determine defaultProps
    
    // average of the rest
    var totalLat = 0
    var totalLon = 0
    var minLat = -999
    var minLon = -999
    var maxLat = -999
    var maxLon = -999
    var n = 0
    for (let i = 0; i < article?.body?.length || 0; i++) {
        if( article.body[i]?.about_entity && article.body[i].about_entity?.latitude){
            // each
            n = n+1;
            totalLat = totalLat + article.body[i].about_entity?.latitude;
            totalLon = totalLon + article.body[i].about_entity?.longitude;

            if( article.body[i].about_entity?.latitude < minLat ){
                minLat = article.body[i].about_entity?.latitude
            }
            if( article.body[i].about_entity?.longitude < minLon ){
                minLon = article.body[i].about_entity?.longitude
            }
            if( article.body[i].about_entity?.latitude > maxLat ){
                maxLat = article.body[i].about_entity?.latitude
            }
            if( article.body[i].about_entity?.longitude > maxLon ){
                maxLon = article.body[i].about_entity?.longitude
            }
        }
        function calcCrow(lat1, lon1, lat2, lon2) 
            {
            var R = 6371; // km
            var dLat = toRad(lat2-lat1);
            var dLon = toRad(lon2-lon1);
            var lat1 = toRad(lat1);
            var lat2 = toRad(lat2);

            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = R * c;
            return d;
            }

            // Converts numeric degrees to radians
            function toRad(Value) 
            {
                return Value * Math.PI / 180;
            }
        // largest dist
        //console.log("orig max", maxLon)
        var largest = Math.max(calcCrow(maxLat, (minLon+maxLon)/2, minLat, (minLon+maxLon)/2), calcCrow((maxLat+minLat)/2, maxLon, (maxLat+minLat)/2, minLon))
        //console.log("lon max", calcCrow(maxLat, (minLon+maxLon)/2, minLat, (minLon+maxLon)/2))
        //console.log("lat max", calcCrow((maxLat+minLat)/2, maxLon, (maxLat+minLat)/2, minLon))
        //console.log("largest max", largest)
        }
    defaultProps['center'] = {
        lat: totalLat/n,
        lng: totalLon/n
    }
    const calculated = Math.round((largest)/560)
    const largest_zoom = 20
    const smallest_zoom = 5
    //console.log("calzoom max", calculated)
    //console.log("zoom max", Math.min( Math.max(smallest_zoom, calculated ),largest_zoom))
    defaultProps['zoom'] = Math.min( Math.max(smallest_zoom, calculated ),largest_zoom)
    
    var index_n = 0;

    if( defaultProps['center']['lat'] ){
        return (
            <div style={{ height: '50vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY }}
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                >
                    {article?.about_entity && article.about_entity?.latitude &&
                    <AnyReactComponentMain
                    lat={article.about_entity.latitude}
                    lng={article.about_entity.longitude}
                    text={article.about_entity?.name}
                    entity={article.about_entity}
                    title = {null}
                    />
                    }
                    { article?.body.map((component,index) => {
                        // only create something on the map if the component has lat,lon
                        if( component?.about_entity){
                            index_n = index_n+1
                            return (
                                <AnyReactComponent
                                    lat={component.about_entity?.latitude}
                                    lng={component.about_entity?.longitude}
                                    text={index_n}
                                    entity={component.about_entity}
                                    component = {component} key={"map_"+index}
                                />
                            )
                        }
                    })}

                </GoogleMapReact>
            </div>
        )
    } else {
        return(<></>)
    }
  }