// extracted by mini-css-extract-plugin
export var box = "article-comments-module--box--12e6b";
export var col = "article-comments-module--col--9870c";
export var commentFormTitle = "article-comments-module--commentFormTitle--d8f65";
export var contactButton = "article-comments-module--contactButton--9de75";
export var error = "article-comments-module--error--6cb1e";
export var formcontrol = "article-comments-module--formcontrol--ef4e3";
export var root = "article-comments-module--root--42098";
export var row = "article-comments-module--row--db30d";
export var success = "article-comments-module--success--c9fb0";
export var title = "article-comments-module--title--07900";